<template>
  <div>
    <div class="row" v-if="data">
      <div class="col-xl-4 col-lg-12">
        <div class="card">
          <div class="card-body">
            <kit-general-10v1 :name="data.fullName" :position="data.title" :department="data.department" :imageUrl="data.imageUrl" :uid="data.uid" :companies="data.companies"/>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h5 class="text-black mt-2 mb-3">
              <strong>Brukere</strong>
            </h5>
            <div v-if="data.companyEmail">
              <strong>Microsoft 365</strong><br>
              brukernavn: {{ data.companyEmail }}
            </div>
            <div v-if="data.leadDesk">
              <strong>Leaddesk</strong><br>
              brukernavn: {{ data.leadDesk }}
            </div>
            <div v-if="data.talkmoreB2C">
              <strong>Talkmore Privat</strong><br>
              brukernavn: {{ data.talkmoreB2C }}
            </div>
            <div v-if="data.nextcomTalkmoreB2B">
              <strong>Nextcom Talkmore Bedrift</strong><br>
              brukernavn: {{ data.nextcomTalkmoreB2B }}
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <user-netofkin :data="data" />
          </div>
        </div>
      </div>
      <div class="col-xl-8 col-lg-12">
        <div class="card">
          <div class="card-body">
            <a-form>
              <h5 class="text-black mt-2 mb-3">
                <strong>Personalia</strong>
                <a-button style="float: right;" @click="toggleEdit" >
                  {{ $t('general.edit') }}
                  </a-button>
              </h5>
              <div class="row" v-if="!edit">
              <div class="col-md-12">
                  Ansattnummer: {{ data.employeeID }}<br>
                  {{ $t('userInfo.name') }}: {{ data.name }}<br>
                  {{ $t('userInfo.surname') }}: {{ data.surname }}<br>
                  {{ $t('userInfo.birthdate') }}: {{ data.birthdate }}<br>
                  {{ $t('userInfo.age') }}: {{ calculateAge(data.birthdate) }}<br>
                  <!-- {{ $t('userInfo.citizenship') }}: {{ data.citizenship }}<br> -->
                  {{ $t('userInfo.socialsecurity') }}: {{ data.personalNumber }}<br>
                  {{ $t('userInfo.bankaccount') }}: {{ data.bankAccount }}<br>
              </div>
              <div class="col-md-12">
                  <h5 class="text-black mt-4 mb-3">
                  <strong>Kontakt informasjon</strong>
                  </h5>
                  {{ $t('userInfo.adress') }}: {{ data.adress }}, {{ data.zip }} {{ data.city }} <br>
                  {{ $t('topBar.profileMenu.email') }}: {{ data.email }}<br>
                  {{ $t('topBar.profileMenu.phone') }}: {{ data.phone }}<br>
              </div>
              </div>
              <a-form v-else @submit="requestChange" :form="form">
                <div class="row">
                  <div class="col-lg-6">
                    <a-form-item label="Navn">
                      <a-input placeholder="Navn"
                        v-decorator="[
                          'name',
                          {
                            initialValue: data.name,
                            rules: [{ required: true, message: 'Venligst skriv et navn' }],
                          }
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-lg-6">
                    <a-form-item label="Etternavn">
                      <a-input placeholder="Etternavn"
                      v-decorator="[
                        'surname',
                        {
                          initialValue: data.surname,
                          rules: [{ required: true, message: 'Venligst skriv et etternavn' }],
                        }
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-lg-6">
                    <a-form-item label="E-post">
                      <a-input placeholder="E-post"
                      v-decorator="[
                        'email',
                        {
                          initialValue: data.email,
                          rules: [
                            { required: true, message: 'Venligst skriv inn et gyldig e-post' },
                            { type: 'email', message: 'E-post ikke gyldig' },
                          ],
                        }
                        ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-lg-6">
                    <a-form-item label="Telefon">
                      <phone-form-component v-model="data.phone"
                      v-decorator="[
                          'phone',
                          {
                            initialValue: data.phone,
                            rules: [{ required: true, message: 'Venligst skriv inn et gyldig telefonnummer' }],
                          }
                          ]"/>
                    </a-form-item>
                  </div>
                  <div class="col-lg-6">
                    <a-form-item label="Personnummer" >
                      {{ data.personalNumber }}
                    </a-form-item>
                  </div>
                  <div class="col-lg-6">
                    <a-form-item label="Bankkonto" has-feedback>
                      <a-input placeholder="Bank account"
                        v-decorator="[
                          'bankAccount',
                          {
                            initialValue: data.bankAccount,
                            rules: [
                              { required: true, message: 'Venligst skriv inn et gyldig kontonummer' },
                              { validator: validateBank },
                            ],
                          }
                          ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-6">
                    <a-form-item label="Adresse">
                      <a-input placeholder="1234 Main St."
                        v-decorator="[
                          'adress',
                          {
                            initialValue: data.adress,
                            rules: [{ required: true, message: 'Venligst skriv inn et gyldig adresse' }],
                          }
                          ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-2">
                    <a-form-item label="Postnummer">
                      <a-input
                        v-decorator="[
                          'zip',
                          {
                            initialValue: data.zip,
                            rules: [
                              { required: true, message: 'Venligst skriv inn et gyldig postnumer' },
                              { validator: validateZip }
                            ],
                          }
                          ]"
                      />
                    </a-form-item>
                  </div>
                  <div class="col-md-4">
                    <a-form-item label="By">
                      <a-input
                        :disabled="true"
                        v-decorator="[
                          'city',
                          {
                            initialValue: data.city,
                          }
                          ]"
                      />
                    </a-form-item>
                  </div>
                </div>
                <a-button style="width: 150px;" type="primary" htmlType="submit" class="mr-3 mt-3 mb-3">Etterspør endringer</a-button>
              </a-form>
              <h5 class="text-black mt-3 mb-3">
                <strong>Ny Passord</strong>
              </h5>
              <div class="row">
                <div class="col-lg-6">
                  <a-button href="https://account.activedirectory.windowsazure.com/ChangePassword.aspx" target="_blank">Bytt passord</a-button>
                </div>
              </div>
              <!-- <div class="row">
                <div class="col-lg-6">
                  <h5 class="text-black mt-2 mb-3">
                    <strong>Profile Avatar</strong>
                  </h5>
                  <a-form-item>
                    <a-upload
                      name="file"
                      :multiple="true"
                      action="//jsonplaceholder.typicode.com/posts/"
                    >
                      <a-button>
                        <a-icon type="upload" />Click to Upload
                      </a-button>
                    </a-upload>
                  </a-form-item>
                </div>
                <div class="col-lg-6">
                  <h5 class="text-black mt-2 mb-3">
                    <strong>Profile Background</strong>
                  </h5>
                  <a-form-item>
                    <a-upload
                      name="file"
                      :multiple="true"
                      action="//jsonplaceholder.typicode.com/posts/"
                    >
                      <a-button>
                        <a-icon type="upload" />Click to Upload
                      </a-button>
                    </a-upload>
                  </a-form-item>
                </div>
              </div> -->
              <div class="form-actions mt-0">
                <!-- <a-button style="width: 150px;" type="primary" htmlType="submit" class="mr-3" @click="updateProfile()">Oppdater</a-button> -->
                <!-- <a-button htmlType="submit">Cancel</a-button> -->

              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12 col-lg-12">
        <user-roles :uid="$store.state.user.id" :oldRoles="$store.state.user.roles"/>
      </div>
    </div>
  </div>
</template>
<script>
import KitGeneral10v1 from '@/components/kit/widgets/General/10v1/index'
import userNetofkin from '@/components/custom/user/netofkin'
import userRoles from '@/components/custom/user/roles'

import { firestore } from '@/services/firebase'
import moment from 'moment'
import { openNotification } from '@/services/powerAutomate'
import { validateBank, calculateAge, findCity } from '@/services/common/hr'
import phoneFormComponent from '@/components/custom/forms/formComponents/telephone/'

export default {
  components: {
    KitGeneral10v1,
    userNetofkin,
    userRoles,
    phoneFormComponent,
  },
  data() {
    return {
      activeKey: '1',
      data: null,
      edit: false,
      roles: this.$store.state.user.roles,
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'editUserInfo ' })
  },
  methods: {
    validateBank,
    calculateAge,
    callback: function (key) {
      this.activeKey = key
    },
    moment,
    toggleEdit() {
      this.edit = !this.edit
    },
    updateProfile() {
      const data = this.data
      data.fullName = data.name.concat(' ', data.surname)
      firestore.collection('users').doc(this.$store.state.user.id).set(data)
        .then(() => this.openNotification('Personalia oppdatert', 'Ditt personalia har blitt opdatert'))
        .catch(() => this.openNotification('Feil ved oppdatering', 'Vennligst sjekk at alt informasjon stemmer eller prøv igjen senere'))
    },
    validateZip(rule, value, callback) {
      const data = findCity(value)
      this.form.setFieldsValue({ city: data })
      if (!data) {
        callback(new Error('Ugylding postnummer'))
      } else {
        callback()
      }
    },
    requestChange(e) {
      // improove table layout
      e.preventDefault()
      this.form.validateFields(async (err, values) => {
        if (!err) {
          try {
            let changesHTML = ''
            const data = values
            const { changeObject, changeArray } = await this.findChanges(this.data, data)
            changeObject.uid = this.data.uid
            if (changeArray.length) {
              const date = String(moment().format('YYYY-MM-DDTHH:mm'))
              changeArray.forEach(change => {
                changesHTML = changesHTML.concat(`<tr><td><span>${change.key}</span></td><td>${change.oldValue}</td><td>${change.newValue}</td></tr>`)
              })
              const description = `${this.data.employeeID} ${this.data.name} ${this.data.surname} ønsker å endre registrert informasjon'<br> Vennligst sjekk gjennom og endre i <br>
              <table><tr><th>Info</th><th>Gammelt Info</th><th>Nytt Info</th></tr>`.concat(changesHTML, '</table>')
              console.log(changesHTML, description)
              firestore.collection('tasks').add({
                title: `${this.data.name} ${this.data.surname} ønsker å endre registrert informasjon`,
                description,
                date,
                callbacks: [
                  {
                    fn: 'editUserInfo',
                    name: 'Godkjenn endring',
                    type: 'CompleteTask',
                    data: changeObject,
                  },
                ],
                type: 'tripletex', // needs to be changed after
                icon: 'fa fa-user',
                status: 'to-do',
                lastOrderedBy: this.$store.state.user.name,
              }).then(() => {
                openNotification('Søknad om endring på info sendt')
                this.edit = false
              })
            } else {
              openNotification('Ingen endring funnet')
              this.edit = false
            }
          } catch (err) {
            console.log(err)
            openNotification('Feil ved søknad om endring på info, vennligst kontakt IT')
          }
        }
      })
    },
    findChanges(oldData, newData) {
      const changeArray = []
      const changeObject = {}
      for (const key in newData) {
        if (oldData[key] !== newData[key]) {
          const change = {
            key: key,
            oldValue: oldData[key],
            newValue: newData[key],
          }
          changeArray.push(change)
          changeObject[key] = newData[key]
        }
      }
      return {
        changeObject,
        changeArray,
      }
    },
  },
  mounted() {
    firestore.collection('users').doc(this.$store.state.user.id).get()
      .then(doc => {
        const data = doc.data()
        data.id = doc.uid
        this.data = data
      })
  },
}
</script>
