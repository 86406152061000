<template>
  <div class="card" v-if="['userAdmin'].some(r => $store.state.user.roles.indexOf(r) >= 0)">
    <div class="card-body">
    <h4 class="text-black mt-2 mb-3">
      <strong>Bruker tilgang</strong>
      <a-popconfirm
        :title="'Er du sikker du ønsker å endre rollene?'"
        ok-text="Ja"
        cancel-text="Nei"
        @confirm="saveRoles"
        @cancel="resetRoles"
        style="float: right;"
      >
        <a-button type="primary" >Lagre</a-button>
      </a-popconfirm>
    </h4>
    <displayRoles v-model="newRoles"/>
    </div>
  </div>
</template>

<script>
import { firestore } from '@/services/firebase'
import { openNotification } from '@/services/powerAutomate'
import displayRoles from '@/components/custom/user/roles/displayRoles'

export default {
  props: ['uid', 'oldRoles'],
  components: {
    displayRoles,
  },
  data() {
    return {
      newRoles: [],
    }
  },
  mounted() {
    this.newRoles = this.oldRoles
  },
  methods: {
    saveRoles() {
      firestore.collection('users').doc(this.uid).update({
        roles: this.newRoles,
      }).then(() => {
        openNotification('Roller har blitt oppdatert', 'Ansatten må refreshe portalen for å få tilgangene')
        this.oldRoles = this.newRoles
      })
    },
    resetRoles() {
      this.newRoles = this.oldRoles
    },
  },
}
</script>

<style>

</style>
