<template>
  <div class="row">
      <div class="col-md-6" v-for="roleCB in rolesCB" :key="roleCB.category">
        <div>
        <!-- <div class="card-body"> -->
          <h5 class="text-black mt-2 mb-3">
            <strong>{{ roleCB.category }}</strong>
          </h5>
          <div v-for="child in roleCB.children" :key="child.value">
            <input type="checkbox" v-model="listLocal" :value="child.value" :id="roleCB.value">
            <label :for="child.value" class="pl-3">{{ child.label }}</label>
          </div>
        <!-- </div> -->
        </div>
      </div>
    </div>
</template>

<script>

const rolesCB = [
  {
    category: 'Administrator',
    children: [
      {
        value: 'userAdmin',
        label: 'Brukeradministrator',
      },
      {
        value: 'CompanySettings',
        label: 'Selskaps Instillinger',
      },
      {
        value: 'underDevelopment',
        label: 'Under utvikling',
      },
    ],
  },
  {
    category: 'Eksterne Brukere',
    children: [
      {
        value: 'manageUsers',
        label: 'Administrere eksterne brukere',
      },
      {
        value: 'orderFK',
        label: 'Bestille Fjordkraft brukere',
      },
      {
        value: 'orderTNFIBER',
        label: 'Bestille Telenor Fiber brukere',
      },
      {
        value: 'orderTMB2C',
        label: 'Bestille Talkmore Privat brukere',
      },
      {
        value: 'orderTelia',
        label: 'Bestille Telia Brukere',
      },
    ],
  },
  {
    category: 'HR',
    children: [
      {
        value: 'HRseeUsersAll',
        label: 'Tilgang til ansattinformasjon for hele selskapet',
      },
      {
        value: 'HRseeUsersDepartment',
        label: 'Tilgang til ansattinformasjon fra egen avdeling',
      },
      {
        value: 'HRseeUsersProject',
        label: 'Tilgang til ansattinformasjon fra egen prosjekt',
      },
      {
        value: 'HRseeUsersProjectDepartment',
        label: 'Tilgang til ansattinformasjon fra egen prosjekt i egen avdeling',
      },
      {
        value: 'HRseePreviousEmployment',
        label: 'Tilgang til tidligere ansattinformasjon',
      },
      {
        value: 'HRseeUsersSensitive',
        label: 'Tilgang til sensitiv ansattinformasjon',
      },
      {
        value: 'HRseeSalary',
        label: 'Tilgang til lønnsinformasjon',
      },
      {
        value: 'HRseeUsersDocuments',
        label: 'Tilgang til ansattes dokumentmappe',
      },
      {
        value: 'HRcreateUsersAll',
        label: 'Opprette ansatte for hele selskapet',
      },
      {
        value: 'HRcreateUsersDepartment',
        label: 'Opprette ansatte for egen avdeling',
      },
      {
        value: 'HRcontractStandard',
        label: 'Opprette standard kontrakter',
      },
      {
        value: 'HRcontractManager',
        label: 'Opprette leder kontrakter',
      },
      {
        value: 'HReditUser',
        label: 'Endre ansattinformasjon',
      },
      {
        value: 'HRoffboardUser',
        label: 'Offboarding av ansatte',
      },
      {
        value: 'HRsettings',
        label: 'HR Instillinger',
      },
    ],
  },
  {
    category: 'Helpdesk',
    children: [
      {
        value: 'helpdeskAll',
        label: 'Tilgang til alle saker',
      },
      {
        value: 'helpdeskTools',
        label: 'Tilgang til verktøy i helpdesk',
      },
      {
        value: 'helpdeskDevelopment',
        label: 'Helpdesk Utvikling',
      },
      // {
      //   value: 'helpdeskDepartment',
      //   label: 'Tilgang til saker fra egen avdeling',
      // },
      // {
      //   value: 'helpdeskProject',
      //   label: 'Tilgang til saker fra egen prosjekt',
      // },
      // {
      //   value: 'helpdeskProjectDepartment',
      //   label: 'Tilgang til saker fra egen prosjekt i egen avdeling',
      // },
      {
        value: 'helpdeskSettings',
        label: 'Helpdesk instillinger',
      },
    ],
  },
  {
    category: 'Timer',
    children: [
      {
        value: 'timesheet',
        label: 'Tilgang til timeliste',
      },
      {
        value: 'timesheetManager',
        label: 'Tilgang til timeliste for hele selskapet',
      },
      {
        value: 'timesheetApproval',
        label: 'Tilgang til å godkjenne timelister',
      },
      {
        value: 'timesheetSettings',
        label: 'Tilgang til timeliste instillinger',
      },
    ],
  },
  {
    category: 'Oppgaver',
    children: [
      {
        value: 'tasks',
        label: 'Tilgang til oppgavemodul',
      },
      {
        value: 'tasksAll',
        label: 'Tilgang til alle oppgaver',
      },
      {
        value: 'orderTNFIBERuser',
        label: 'Bestille Telenor Brukere',
      },
      {
        value: 'orderTMB2Cuser',
        label: 'Bestille Talkmore Privat Brukere',
      },
      {
        value: 'slackUserAdmin',
        label: 'Administrasjon av Slack Brukere',
      },
      {
        value: 'FKUserAdmin',
        label: 'Administrasjon av Fjordkraft Brukere',
      },
    ],
  },
  {
    category: 'Leads',
    children: [
      {
        value: 'leads',
        label: 'Tilgang til leads modul',
      },
      {
        value: 'leadsSettings',
        label: 'Tilgang til leads instillinger',
      },
    ],
  },
  {
    category: 'Talkmore Privat',
    children: [
      {
        value: 'TalkmoreB2CReportManager',
        label: 'Talkmore Privat Leder rapport',
      },
      {
        value: 'TalkmoreB2CReport',
        label: 'Talkmore Privat selger rapport',
      },
      {
        value: 'talkmoreB2Csell',
        label: 'Talkmore Privat registrere salg',
      },
      {
        value: 'talkmoreB2Cedit',
        label: 'Talkmore Privat endre salg',
      },
      {
        value: 'talkmoreB2Cep',
        label: 'Talkmore Privat Brukere',
      },
      {
        value: 'talkmoreB2Csettings',
        label: 'Talkmore Privat instillinger',
      },
    ],
  },
  {
    category: 'eSignering',
    children: [
      {
        value: 'signature',
        label: 'Signeringsmodul',
      },
      {
        value: 'signatureSeeDepartment',
        label: 'Tilgang til esignering for egen avdeling',
      },
      {
        value: 'signatureSeeAll',
        label: 'Tilgang til esignering for hele selskapet',
      },
      {
        value: 'signatureManual',
        label: 'Opprette manuelle dokumenter til eSignering',
      },
      {
        value: 'signatureNoConfirmation',
        label: 'Send til signering uten godkjenning',
      },
    ],
  },
  {
    category: 'Rapporter',
    children: [
      {
        value: 'reports',
        label: 'Tilgang til Rapport modul',
      },
    ],
  },
  {
    category: 'TV Instillinger',
    children: [
      {
        value: 'TVSettingsAll',
        label: 'TV Instillinger for eget selskap',
      },
      {
        value: 'TVSettingsDep',
        label: 'TV Instillinger for egen avdeling',
      },
    ],
  },
  {
    category: 'CMS',
    children: [
      {
        value: 'cms',
        label: 'CMS',
      },
    ],
  },
  {
    category: 'Verktøy',
    children: [
      {
        value: 'carrierLookup',
        label: 'Carrier lookup',
      },
      {
        value: 'supporttables',
        label: 'Support Tables',
      },
    ],
  },
]
export default {
  props: ['list'],
  model: {
    prop: 'list',
    event: 'listchange',
  },
  computed: {
    listLocal: {
      get: function() {
        return this.list
      },
      set: function(value) {
        this.$emit('listchange', value)
      },
    },
  },
  data() {
    return {
      rolesCB,
    }
  },
}
</script>
