<template>
  <div>
    <a-button style="float: right;" @click="edit = !edit">
        Endre
      </a-button>
    <h5 class="text-black mt-2 mb-3">
      <strong>Pårørende</strong>
    </h5>
    <div v-if="!edit">
      <div>
        <div v-for="(netOfKin, index) in data.netOfKin" :key="index" class="mt-2" >
          <strong>{{ netOfKin.typeOfRelationship === 'SPOUSE' ? 'Ektefelle' : netOfKin.typeOfRelationship === 'PARTNER' ? 'Samboer/Partner' : netOfKin.typeOfRelationship === 'PARENT' ? 'Forelder' : netOfKin.typeOfRelationship === 'CHILD' ? 'Barn' : netOfKin.typeOfRelationship === 'SIBLING' ? 'Søsken' : ''  }}</strong><br>
          <span>{{ netOfKin.name }}</span><br>
          <a :href="'tel:'.concat(netOfKin.phoneNumber)">{{ netOfKin.phoneNumber }}</a>
        </div>
      </div>
      <a-button style="width: 150px;" type="primary"  class="mt-3" @click="displayNewNetOfKin = !displayNewNetOfKin">Ny Pårørende</a-button>
      <div v-if="displayNewNetOfKin" class="mt-3">
        <a-input placeholder="Navn" v-model="newNetOfKin.name" class="mt-3"></a-input>
        <a-input placeholder="Telefonnummer" v-model="newNetOfKin.phoneNumber" class="mt-3"></a-input>
        <a-select v-model="newNetOfKin.typeOfRelationship" style="width: 100%" placeholder="Velg forhold" class="mt-3">
          <a-select-option value="SPOUSE">
            Ektefelle
          </a-select-option>
          <a-select-option value="PARTNER">
            Samboer/Partner
          </a-select-option>
          <a-select-option value="PARENT">
            Forelder
          </a-select-option>
          <a-select-option value="CHILD">
            Barn
          </a-select-option>
          <a-select-option value="SIBLING">
            Søsken
          </a-select-option>
        </a-select>
        <a-button style="width: 150px;" type="primary"  class="mt-3" @click="createNetOfKin">Opprett</a-button>
      </div>
    </div>
    <div v-else>
      <div v-for="(netOfKin, index) in data.netOfKin" :key="index" class="mt-2" >
        <a-select v-model="netOfKin.typeOfRelationship" style="width: 100%" placeholder="Velg forhold" class="mt-3">
          <a-select-option value="SPOUSE">
            Ektefelle
          </a-select-option>
          <a-select-option value="PARTNER">
            Samboer/Partner
          </a-select-option>
          <a-select-option value="PARENT">
            Forelder
          </a-select-option>
          <a-select-option value="CHILD">
            Barn
          </a-select-option>
          <a-select-option value="SIBLING">
            Søsken
          </a-select-option>
        </a-select>
        <a-input placeholder="Navn" v-model="netOfKin.name" class="mt-3"></a-input>
        <a-input placeholder="Telefonnummer" v-model="netOfKin.phoneNumber" class="mt-3"></a-input>
        <a-button type="secondary"  class="mt-3" @click="removeNetOfKin(index)">Fjern {{ netOfKin.name }} som pårørende</a-button>
      </div>
      <a-button type="primary"  class="mt-3" @click="updateNetOfKin()">Oppdater</a-button>
    </div>
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import 'firebase/firestore'

import { openNotification } from '@/services/powerAutomate'

export default {
  props: ['data'],
  data() {
    return {
      displayNewNetOfKin: false,
      newNetOfKin: {},
      edit: false,
    }
  },
  methods: {
    createNetOfKin() {
      if (this.newNetOfKin.name && this.newNetOfKin.phoneNumber && this.newNetOfKin.typeOfRelationship) {
        this.data.netOfKin ? this.data.netOfKin.push(this.newNetOfKin) : this.data.netOfKin = [this.newNetOfKin]
        firebase.firestore().collection('users').doc(this.data.uid).update({
          netOfKin: this.data.netOfKin,
        }).then(() => {
          this.displayNewNetOfKin = false
          this.newNetOfKin = {}
          openNotification('Informasjon lagret')
        }).catch(err => openNotification('Feil ved lagring av informasjon', err))
      } else {
        openNotification('Mangler noen felt')
      }
    },
    removeNetOfKin(i) {
      const netOfKin = this.data.netOfKin
      this.data.netOfKin = netOfKin.filter((_, index) => index !== i)
      this.updateNetOfKin().catch(() => {
        this.data.netOfKin = netOfKin
      })
    },
    async updateNetOfKin() {
      firebase.firestore().collection('users').doc(this.data.uid).update({
        netOfKin: this.data.netOfKin,
      }).then(() => {
        openNotification('Informasjon lagret')
        this.edit = false
      }).catch(err => openNotification('Feil ved lagring av informasjon', err))
    },
  },
}
</script>

<style scoped>
  .ant-input.validfield {
    border-color: green!important
  }
  .ant-input.invalidfield {
    border-color: red!important
  }
</style>
