<template>
  <div class="d-flex flex-wrap flex-column align-items-center">
      <a-upload
        name="avatar"
        list-type="picture-card"
        class="avatar-uploader"
        style="width: inherit"
        :show-upload-list="false"
        :before-upload="beforeUpload"
        @change="handleChange"
        v-if="uid"
      >
        <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width: 128px;" />
        <div v-else>
          <a-icon :type="loading ? 'loading' : 'plus'" />
          <div class="ant-upload-text">
            Upload
          </div>
        </div>
      </a-upload>
      <!-- <img src="avatar" :alt="name" v-if="avatar" />
      <img src="resources/images/avatars/avatar-2.png" :alt="name" v-else/> -->
    <div class="text-center">
      <div class="text-dark font-weight-bold font-size-18">{{ name }}</div>
      <div class="text-uppercase font-size-12">{{ position }}</div>
      <div class="text-uppercase font-size-12" v-if="$store.state.user.companies.length > 1"> {{ companies.join(', ') }}</div>
      <div class="text-uppercase font-size-12" v-if="department"> Avdeling {{ department }}</div>
      <div class="text-uppercase font-size-12" v-if="department"> {{ type }}</div>
      <div class="text-uppercase font-size-12 mb-3" v-if="status"> Status: {{ status }}</div>
      <!-- <button type="button" class="btn btn-primary" :class="$style.btnWithAddon">
        <span :class="$style.btnAddon">
          <i class="fe fe-plus-circle" :class="$style.btnAddonIcon" />
        </span>
        Request Access
      </button> -->
    </div>
  </div>
</template>
<script>
import * as firebase from 'firebase/app'
import 'firebase/storage'
import heic2any from 'heic2any'

function getBase64(img, callback) {
  const reader = new FileReader()
  reader.addEventListener('load', () => callback(reader.result))
  reader.readAsDataURL(img)
}

export default {
  name: 'KitGeneral',
  props: ['name', 'imageUrl', 'position', 'department', 'companies', 'status', 'uid', 'type'],
  data() {
    return {
      loading: false,
    }
  },
  methods: {
    handleChange(info) {
      if (info.file.status === 'uploading') {
        this.loading = true
        return
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, imageUrl => {
          this.imageUrl = imageUrl
          this.loading = false
        })
      }
    },
    async beforeUpload(file) {
      const isJpgOrPngOrHeic =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/heic' ||
      file.type === 'image/heif'

      if (!isJpgOrPngOrHeic) {
        this.$message.error('You can only upload JPG, PNG, or HEIC files!')
      }
      if (isJpgOrPngOrHeic) {
        // If the file is a HEIC/HEIF image, convert it to JPEG first
        if (file.type === 'image/heic' || file.type === 'image/heif') {
          const convertedFile = await heic2any({ blob: file, toType: 'image/jpeg' })
          file = new File([convertedFile], 'converted-image.jpg', { type: 'image/jpeg' })
        }
        const resizedImage = await this.resizeAndConvertImage(file)
        this.uploadImage(resizedImage)
        return false // This prevents the default upload behavior
      }
      return false
    },
    uploadImage(image) {
      const ref = firebase.storage().ref().child(`users/profile/${this.uid}`)
      ref.put(image).then((snapshot) => {
        console.log('Uploaded a blob or file!')
        return snapshot.ref.getDownloadURL()
      }).then(downloadURL => {
        this.imageUrl = downloadURL
        return firebase.firestore().collection('users').doc(this.uid).update({
          imageUrl: downloadURL,
        })
      }).then(() => {
        console.log('image uploaded')
      }).catch(err => {
        console.log(err)
      })
    },
    async resizeAndConvertImage(file) {
      const maxSize = 500 // The maximum width/height of the resized image
      const img = document.createElement('img')
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      const imageDataUrl = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.onload = (event) => {
          resolve(event.target.result)
        }
        reader.readAsDataURL(file)
      })

      img.src = imageDataUrl

      return new Promise((resolve) => {
        img.onload = () => {
          const scale = Math.min(maxSize / img.width, maxSize / img.height)
          canvas.width = img.width * scale
          canvas.height = img.height * scale

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

          canvas.toBlob((blob) => {
            resolve(new File([blob], 'resized-image.jpg', { type: 'image/jpeg' }))
          }, 'image/jpeg')
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";

.avatar-uploader > .ant-upload {
  width: 128px;
  height: 128px;
}

.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
